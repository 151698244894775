import { ReactElement } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useAnalytics } from '@/hooks/use-analytics';
import { analyticalData } from '@/config/analytics';
import { links } from '@/config/links';

import Checkbox from '@/icons/checkbox-statistic.svg';

const messages = defineMessages({
  provenOnMillions: {
    defaultMessage: 'Proven on millions of Macs',
  },
  performanceImprovement: {
    defaultMessage: 'Performance improvement of',
  },
  averageMonthlyFigures: {
    defaultMessage: '*These data are average monthly figures from CleanMyMac.',
  },
  deleteUnnecessaryData: {
    defaultMessage: 'Delete unnecessary data in',
  },
  dataAmount: {
    defaultMessage: '35 million GB',
  },
  removedVirus: {
    defaultMessage: 'Removed 1 virus',
  },
  virusCount: {
    defaultMessage: '328,000',
  },
  speedImprovement: {
    defaultMessage: 'Speed improvement in the case of',
  },
  speedCount: {
    defaultMessage: '9.8 million',
  },
  getTrial: {
    defaultMessage: 'Get Trial',
  },
});

const statisticsData = [
  {
    id: 1,
    extraClasses: 'bg-image-junk',
    message: messages.deleteUnnecessaryData,
    value: messages.dataAmount,
  },
  {
    id: 2,
    extraClasses: 'bg-image-protection',
    message: messages.removedVirus,
    value: messages.virusCount,
  },
  {
    id: 3,
    extraClasses: 'bg-image-performance md:col-span-2 lg:col-span-1',
    message: messages.speedImprovement,
    value: messages.speedCount,
  },
];

const Statistics = (): ReactElement => {
  const { formatMessage } = useIntl();
  const { sendEvent } = useAnalytics();

  const handleGetTrialClick = () => {
    sendEvent({
      eventCategory: analyticalData.eventCategory,
      eventAction: analyticalData.eventAction.onGetTrialClick,
      eventLabel: analyticalData.eventLabel.middleFirst,
    });
  };

  return (
    <section className="mx-5 mb-15 md:mx-4 md:mb-24">
      <div className="mx-auto max-w-screen-lg">
        <div className="mx-auto mb-10 lg:flex lg:justify-between">
          <p className="mb-5 text-8 font-semibold leading-tight md:text-10 lg:mb-0">
            {formatMessage(messages.provenOnMillions)}
            <br className="hidden md:block" />
            <span className="text-[#FB35B5]">{formatMessage(messages.performanceImprovement)}</span>
          </p>
          <p className="max-w-87 text-sm font-medium leading-normal opacity-50 md:pt-1 md:text-base md:leading-tight">
            {formatMessage(messages.averageMonthlyFigures)}
          </p>
        </div>
        <ul className="mb-10 grid gap-5 md:mb-14 md:grid-cols-2 md:gap-8 lg:grid-cols-3">
          {statisticsData.map((item) => (
            <li
              key={item.id}
              className={`h-[485px] rounded-7.5 bg-[#f5f5f5] bg-112.5 bg-right-bottom bg-no-repeat p-8 ${item.extraClasses}`}
            >
              <div className="mb-3 flex items-center space-x-2">
                <Checkbox />
                <p className="font-medium text-[#78797A]">{formatMessage(item.message)}</p>
              </div>
              <p className="text-5xl font-semibold md:text-13">{formatMessage(item.value)}</p>
            </li>
          ))}
        </ul>
      </div>
      <a
        href={links.cmmTrial}
        className="btn-new btn-new--primary btn-new--primary-outline mx-5 w-auto md:mx-auto md:w-fit"
        data-qa="middle-first__get-trial"
        onClick={handleGetTrialClick}
      >
        {formatMessage(messages.getTrial)}
      </a>
    </section>
  );
};

export { Statistics };
