import { ReactElement } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import RequirementsIcon from '@/icons/requirements.svg';
import RatingIcon from '@/icons/rating.svg';
import PriceIcon from '@/icons/price.svg';
import InfoIcon from '@/icons/info.svg';
import Stars from '@/icons/stars5.svg';
import Display from '@/icons/display.svg';

const messages = defineMessages({
  systemRequirements: {
    defaultMessage: 'System requirements:',
  },
  minimumDisplayTitle: {
    defaultMessage: 'Minimum display size:',
  },
  minimumDisplay: {
    defaultMessage: '1200 pixels wide {br}by 800 pixels high',
  },
  macos: {
    defaultMessage: 'macOS 11+',
  },
  ratingTitle: {
    defaultMessage: 'Rating:',
  },
  rating: {
    defaultMessage: '/ 4.9*',
  },
  priceTitle: {
    defaultMessage: 'Price:',
  },
  price: {
    defaultMessage: 'Price: ¥4,388',
  },
  info: {
    defaultMessage: 'Latest version:',
  },
  version: {
    defaultMessage: '5.0.3 | November 12, 2024',
  },
  note: {
    defaultMessage: '*4.9 - Rated based on 539 reviews for all versions',
  },
  buildSize: {
    defaultMessage: '320 MB',
  },
});

const Requirements = (): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <section className="mx-auto mb-15 w-full max-w-290 px-5 md:mb-24">
      <div className="mb-4 flex w-full flex-col flex-wrap rounded-5 bg-[rgb(242,242,244)] px-6 pb-2 pt-10 text-center md:flex-row">
        <div className="mb-8 flex h-full flex-grow flex-col items-center justify-center px-7 md:justify-start">
          <RequirementsIcon className="mb-3 md:mb-5" />
          <p className="mb-1 text-xs opacity-50">{formatMessage(messages.systemRequirements)}</p>
          <p className="text-sm">
            {formatMessage(messages.macos)}, {formatMessage(messages.buildSize)}
          </p>
        </div>
        <div className="mb-8 flex h-full flex-grow flex-col items-center justify-center px-7 md:justify-start">
          <Display className="mb-3 md:mb-5" />
          <p className="mb-1 text-xs opacity-50">{formatMessage(messages.minimumDisplayTitle)}</p>
          <p className="text-sm">
            {formatMessage(messages.minimumDisplay, {
              br: <br />,
            })}
          </p>
        </div>
        <div className="mb-8 flex h-full flex-grow flex-col items-center justify-center px-7 md:justify-start">
          <RatingIcon className="mb-3 md:mb-5" />
          <p className="mb-1 text-xs opacity-50">{formatMessage(messages.ratingTitle)}</p>
          <div className="flex items-center justify-center">
            <Stars />
            <p className="text-sm">{formatMessage(messages.rating)}</p>
          </div>
        </div>
        <div className="mb-8 flex h-full flex-grow flex-col items-center justify-center px-7 md:justify-start">
          <PriceIcon className="mb-3 md:mb-5" />
          <p className="mb-1 text-xs opacity-50">{formatMessage(messages.priceTitle)}</p>
          <p className="text-sm">{formatMessage(messages.price)}</p>
        </div>
        <div className="mb-8 flex h-full flex-grow flex-col items-center justify-center px-7 md:justify-start">
          <InfoIcon className="mb-3 md:mb-5" />
          <p className="mb-1 text-xs opacity-50">{formatMessage(messages.info)}</p>
          <p className="text-sm">{formatMessage(messages.version)}</p>
        </div>
      </div>
      <p className="text-center text-xs opacity-50">{formatMessage(messages.note)}</p>
    </section>
  );
};

export { Requirements };
